<template>
  <div class="p-container">
    <div class="left">
      <a-list
        :loading="loading"
      >
        <a-list-item v-for="item in blogs" :key="item.id" class="eachItem">
          <img :src="item.cover" alt="" height="130" width="200" @click="goDetail(item)" 
          v-if="item.cover !== undefined && item.cover !== null">
          <div class="textSide">
            <h2 class="title" @click="goDetail(item)">{{item.title}}</h2>
            <span style="float: left;"><a-tag color="#7B8184">
              标签:</a-tag><a-tag v-for="(item,index) in item.tags" :key="index" :color="color[index%7]">{{item.name}}</a-tag>
            </span>
            <br />
            <a-descriptions class="desc">
              <a-descriptions-item @click="goDetail(item)">
                {{item.description}}
              </a-descriptions-item>
            </a-descriptions>
            <div class="count">
              <span class="countItem"><user-outlined />{{item.author}}</span>
              <span class="countItem"><heart-filled />{{item.likes}}</span>
              <span class="countItem"><message-outlined />{{item.cmtNum}}</span>
              <span class="countItem"><calendar-outlined />{{item.pubTime}}</span>
              <span class="countItem"><eye-outlined />{{item.viewNum}}</span>
              <span class="countItem"><star-outlined />{{item.stars}}</span>
            </div>
          </div>
        </a-list-item>
      </a-list>
      <h2 class="loadMoreinfo" v-if="pagination.pages === pagination.current">没有更多了......</h2>
      <a-button type="dashed" size="large" shape="round" v-if="pagination.pages > pagination.current"
        @click="loadMoreList"
        >加载更多</a-button>
    </div>
    <!-- right -->
    <div class="right">
      <main-sider @loadTagBlog="loadTagBlog"></main-sider>
    </div>
  </div>
</template>
<script>
  import { UserOutlined, HeartFilled, MessageOutlined, CalendarOutlined, EyeOutlined, StarOutlined } from '@ant-design/icons-vue';
  import MainSider from '../sider/MainSider.vue'
  import * as BLOG from '../../api/blog.js';
  export default {
    name: 'ContentList',
    components: {
      UserOutlined,
      HeartFilled,
      MessageOutlined,
      CalendarOutlined,
      EyeOutlined,
      StarOutlined,
      MainSider
    },
    data() {
      return {
        loading: false,
        showLoadingMore: true,
        loadingMore: false,
        pagination: {
          current: 1,
          size: 10,
          total: 10,
          pages: 0,
        },
        blogs: [],
        classId: null,
        color: ['red', 'pink', 'orange', 'green', 'cyan','blue', 'purple'],
      }
    },
    mounted() {
      //false代表不是loadmore
      this.loadData(null, 1,10, false)
    },
    methods: {
      loadMoreList() {
        // console.log('load more')
        if(this.pagination.current+1>this.pagination.pages) {
          return;
        }
        this.loadData(this.classId, this.pagination.current+1, this.pagination.size, true)
      },
      goDetail(item) {
        this.$router.push({
          path: '/blog',
          query: {
            id: item.id
          }
        })
      },
      loadData(classId, page, size, loadMore) {
        let params = new FormData()
        if(classId!=null) {
          params.append('tid', classId)
        }
        params.append('page', page)
        params.append('size', size)
        BLOG.loadBlogList(params).then(res=>{
          // console.log('blog list', res)
          if(res.data.code == 200) {
            if(loadMore) {
              //将新数据添加进去
              this.blogs.push.apply(this.blogs, res.data.data.records)
            }else {
              this.blogs = res.data.data.records;
            }
            this.pagination.current = res.data.data.current;
            this.pagination.size = res.data.data.size;
            this.pagination.total = res.data.data.total;
            this.pagination.pages = res.data.data.pages
          }
        })
      },
      //更换热门 tags
      tagChange() {
        this.tagLoading = true;
        let page = this.hotTags.pagination.current + 1;
        if(page > this.hotTags.pagination.pages) {
          page = 1;
        }
        this.loadHotTags(page, this.hotTags.pagination.size);
      },
      loadTagBlog(item) {
        // console.log('class', item)
        if(this.classId == null || this.classId !== item.id) {
          this.pagination.page = 1
          this.pagination.size = 10
        }
        this.classId = item.id
        this.loadData(this.classId, this.pagination.page, this.pagination.size)
      }
    },
  }
</script>

<style scoped>
  .p-container {
    display: flex;
    margin: auto;
  }
  .left {
    width: 650px;
  }
  .right {
    margin-top: 20px;
    width: 280px;
    margin-left: 30px;
  }
  .eachItem {
    width: 650px;
    height: 220px;
    padding: 10px 10px;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 20px;
    cursor: pointer;
    transition: all 1s;
  }
  .eachItem:hover {
    border-color: rgb(125, 164, 235) ;
    box-shadow: 0 0 20px 2px rgba(216, 79, 205, 0.4);
    transition:0.3s;
    border-style:solid;
    border-width: 3px;
  }

  .textSide {
    width: 450px;
    margin-left: 16px;
  }
  .title {
    text-align: left;
    font-size: 18px;
    line-height: 27px;
    height: 27px;
    font-family: -apple-system,SF UI Display,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,WenQuanYi Micro Hei,sans-serif;
  }
  .desc {
    margin-top: 6px;
    text-align: left;
    text-overflow: ellipsis;
    font-size: 13px;
    color: #999;
    line-height: 24px;
    max-height: 72px;
  }
  .count {
    text-align: left;
  }
  .countItem:not(:first-child) {
    margin-left: 12px;
  }
  .loadMoreinfo {
    text-align: center;
    color: #fff;
  }
</style>