<template>
  <div class="container">
    <div class="about">
      <span style="font-size: 18px;">关于我们</span>
      <p style="font-size: 10px; padding: 10px; text-align: left; " >
        欢迎大家分享自己的学习经历及技术经验！
      </p>
      <div class="countData">
        <div v-for="(item, index) in about" :key="index" class="dataItem">
          <div style="font-size: 0.8rem;">{{item.title}}</div>
          <div style="font-size: 1.25rem;">{{item.num}}</div>
        </div>
      </div>
      <div class="followUs">
        <div class="gitee">
          <img src="../../assets/gitee.jpg" style="height: 30px; width: 30px;" />
          <a href="https://gitee.com/Mr_jiajie" target="_blank" style="color: #525252; margin-left: 6px;">
            https://gitee.com/Mr_jiajie
          </a>
        </div>
        <div class="qq">
          <img src="../../assets/qq.png" style="height: 30px; width: 30px;" />
          <a href="tencent://AddContact/?fromId=50&fromSubId=1&subcmd=all&uin=937652213" target="_blank" style="color: #525252; margin-left: 6px;">
            937652213
          </a>
        </div>
      </div>
    </div>
    <!-- about end -->
    <div class="tags">
      <span style="font-size: 18px;">热门标签</span>
      <div class="tagsList">
        <div class="tagsContent">
          <a-tag v-for="(item, index) in hotTags.data" :key="index" :color="color[index%7]"
          class="tagItem"
          @click="loadTagBlog(item)"
          >{{item.name}} 
          <span style="font-size: 8px;">({{item.num}})</span></a-tag>
        </div>
        <div class="tagsBtn">
          <a-button type="dashed" shape="round" @click="tagChange" :loading="tagLoading">更换</a-button>
        </div>
      </div>
    </div>
    <!-- tags end -->
    <div class="weather">
      <div id="he-plugin-standard" >

      </div>
    </div>
  </div>
</template>
<script>
  import * as TAGS from '../../api/tags.js';
  import { countSiteBaseData } from '../../api/blog.js'
  import { onMounted } from 'vue';
  export default {
    name: 'MainSider',
    setup(props, ctx) {
      onMounted(() => {
        window.WIDGET = {
          "CONFIG": {
            "layout": "2",
            "width": 280,
            "height": 270,
            "background": "1",
            "dataColor": "FFFFFF",
            "borderRadius": "5",
            "key": "6395825124b74a299cb5e9afedf81c4a"
          }
        }
      });
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://widget.qweather.net/standard/static/js/he-standard-common.js?v=2.0'
      document.getElementsByTagName('head')[0].appendChild(script)

      const loadTagBlog = (item) => {
        // console.log(item)
        ctx.emit('loadTagBlog', item)
      }
      return {
        loadTagBlog,
      };
    },
    data() {
      return {
        about: [],
        color: ['red', 'pink', 'orange', 'green', 'cyan','blue', 'purple'],
        hotTags: {
          pagination: {
            current: 1,
            size: 10,
            total: 0,
            pages: 0,
          },
          data: []
        },
        tagLoading: false
      }
    },
    mounted() {
      this.loadHotTags(1,10)
      this.siteBaseData()
    },
    methods: {
      loadHotTags(page, size) {
        let params = new FormData()
        params.append('page', page)
        params.append('size', size)
        this.tagLoading = true;
        TAGS.loadHotTags(params).then(res=>{
          // console.log('hot tag ', res)
          this.tagLoading =false;
          this.hotTags.data = res.data.data.records;
          this.hotTags.pagination.total = res.data.data.total;
          this.hotTags.pagination.current = res.data.data.current;
          this.hotTags.pagination.pages = res.data.data.pages;
        })
      },
      tagChange() {
        this.hotTags.pagination.current = this.hotTags.pagination.current%this.hotTags.pagination.pages;
        this.loadHotTags(this.hotTags.pagination.current+1, 10)
      },
      siteBaseData() {
        countSiteBaseData().then(res=> {
          // console.log(res)
          if(res.data.code==200){
            let author = {
              title: '创作者',
              num: res.data.data.authorsNum
            }
            let blogs = {
              title: '博客',
              num: res.data.data.blogsNum
            }
            let tags = {
              title: '标签',
              num: res.data.data.tagsNum
            }
            this.about = [author, blogs, tags]
          }
        })
      }
    }
  }
</script>

<style scoped>
  .container {
    display: block;
    height: 100%;
    width: 100%;
  }
  .about {
    height: 300px;
    width: 280px;
    border-radius: 12px;
    background-color: #fff;
    text-align: center;
  }
  .countData {
    display: flex;
    justify-self: center;
    padding: .875rem 0;
  }

  .dataItem {
    flex: 1;
    text-align: center;
  }
  .followUs {
    margin-top: 12px;
    height: 90px;
    text-align: left;
  }
  .gitee {
    width: 70%;
    height: 40px;
    line-height: 40px;
    margin: auto;
    font-size: 12px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 2px;
  }
  .qq {
    width: 70%;
    height: 40px;
    line-height: 40px;
    margin: 10px auto;
    font-size: 12px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 2px;
  }
  .tags {
    height: 240px;
    min-height: 100px;
    width: 280px;
    border-radius: 12px;
    background-color: #fff;
    text-align: center;
    margin-top: 20px;
  }
  .tagsContent {
    display: flex;
    flex-flow:row wrap;
    align-content:flex-start;
  }
  .tagsBtn {
    margin-top: 20px;
  }
  .tagItem {
    flex: 0 0 18%;
    margin-top: 12px;
  }
  .weather {
    margin-top: 20px;
    width: 280px;
  }
</style>