<template>
  <div id="components-back-top-demo-custom">
    <a-back-top>
      <div class="ant-back-top-inner"><arrow-up-outlined /></div>
    </a-back-top>
  </div>
</template>

<script>
  import { ArrowUpOutlined } from '@ant-design/icons-vue';
  export default {
    name: 'MyBackTop',
    components: {
      ArrowUpOutlined
    }
  }
</script>

<style scoped>
#components-back-top-demo-custom .ant-back-top {
  bottom: 100px;
}
#components-back-top-demo-custom .ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 10px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
</style>