<template>
  <a-layout-content style="text-align: center; width: 100%; display: flex; " class="container">
    <!-- 可加一些通知消息 -->
    <content-list></content-list>
    <my-back-top></my-back-top>
  </a-layout-content>
</template>

<script>
  import ContentList from './ContentList.vue';
  import MyBackTop from '@/components/common/MyBackTop'
  export default {
    name: 'HomeContent',
    components: {
      ContentList,
      MyBackTop
    }
  }
</script>

<style scoped>
  .container {
    background-image: url('../../assets/sky.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* opacity: 0.9; */
    /* filter: none; */
  }
</style>